<template>
  <div class="footer-nav flex-center">
    <p>预见未来（辽宁）计算机软件科技有限公司</p>
    <p style="margin-left: 25px">
      电话：18240086766 邮箱：yujianweilai01@qq.com
    </p>
  </div>
</template>

<script>
export default {
  name: "FooterNav",
};
</script>