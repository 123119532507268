import axios from "axios";
import { api } from "./server";

// 配置axios
const http = axios.create({
    baseURL: api,
    timeout: 5000
})


export default http